import {Component, EventEmitter, Input, model, ModelSignal, Output, ViewChild} from '@angular/core';
import {ConfirmDialogComponent} from 'src/app/components/utilities/confirm-dialog/confirm-dialog.component';
import {Color} from 'src/app/enums/core/color.enum';
import {Severity} from 'src/app/enums/core/severity.enum';
import {ButtonsModule} from 'src/app/modules/buttons/buttons.module';

const RAD_STANDALONE_COMPONENTS = [
    ConfirmDialogComponent
];

const RAD_MODULES = [
    ButtonsModule
];

@Component({
    selector: 'app-info-dialog',
    standalone: true,
    imports: [
        ...RAD_STANDALONE_COMPONENTS,
        ...RAD_MODULES
    ],
    templateUrl: './info-dialog.component.html'
})
export class InfoDialogComponent {
    @ViewChild('confirmDialog') protected confirmDialog?: ConfirmDialogComponent;

    @Input() public header?: string | null;
    @Input() public bodyContent?: string | null;
    @Input() public htmlContent?: boolean;
    @Input() public confirmButtonLabel: string = 'close';
    @Input() public noCloseOnConfirm: boolean = false;

    @Output() public confirm = new EventEmitter<void>();

    public visible: ModelSignal<any> = model(false);

    protected readonly Color = Color;
    protected readonly Severity = Severity;

    protected onConfirm() {
        this.confirm.emit();
        this.close();
    }

    public open() {
        this.visible.set(true);
    }

    public close() {
        this.visible.set(false);
    }
}